
					@import './src/sass/variables';
					@import './src/sass/mixins';
				




















































































































































































































































































.items {
	display: flex;
	flex-flow: row nowrap;
	gap: $default_padding * 4;
	position: relative;
}

ol {
	flex: 1;
	list-style-type: none;
	margin: 0;
	padding: 0;
	max-width: 500px;

	&.lhs li {
		justify-content: space-between;
	}

	&.rhs li {
		justify-content: flex-start;
	}
}

li {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	gap: 10px;
	background: $color__white;
	font-size: 1.25rem;
	width: 100%;
	border: none;
	box-shadow: $box_shadow__input;
	border-radius: $border_radius;
	padding: 0.5rem 1rem;
	margin-bottom: $default_padding / 2;
	line-height: 1em;
	transition: box-shadow 0.2s ease-out;
	cursor: pointer;

	.dot {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: $color__semi_dark;
		flex: 0 0 10px;
	}

	&.selected {
		outline: 3px solid $color__light_blue;

		.dot {
			background-color: $color__light_blue;
		}
	}

	&.correct .dot {
		background-color: $color__green;
	}

	&.incorrect .dot {
		background-color: $color__red;
	}

	img {
		max-width: 95%;
		max-height: 150px;
	}
}

.lines::v-deep {
	position: absolute;

	.line {
		position: absolute;
		height: 10px;
		background-color: $color__light_blue;
		border-radius: 10px;
		box-shadow: 0 0 0 1px $color__background;

		&.correct {
			background-color: $color__green;
		}

		&.incorrect {
			background-color: $color__red;
		}

		&[data-start]:hover {
			background-color: $color__red;
			cursor: pointer;
		}
	}
}
